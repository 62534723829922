import React from "react";
import ParticlesBg from "particles-bg";
import * as Scroll from 'react-scroll';
import { ssInit } from './main';
const Header = () => {

  let config = {
    num: [4, 10],
    rps: 0.1,
    radius: [5, 30],
    life: [1, 3],
    v: [1, 3],
    tha: [-360, 360],
    alpha: [0.6, 0],
    scale: [1, 6],
    color: ["#336699", "#114477"],
    cross: "bround", // cross or bround
    random: 50,  // or null,
    g: 0,    // gravity
    //f: [-1, -1], // force
  };
  ssInit();
  var Link = Scroll.Link;
  return (<>
    <header className="s-header">
      <div className="row s-header__nav-wrap">
        <nav className="s-header__nav">
          <ul>
            <li className="current"><a className="smoothscroll" href="#hero">Home</a></li>
            <li><a className="smoothscroll" href="#contact">Contact Us</a></li>
          </ul>
        </nav>
      </div>

      <a className="s-header__menu-toggle" href="#0" title="Menu">
        <span className="s-header__menu-icon"></span>
      </a>
    </header>

    <section id="hero" className="s-hero target-section">
      <ParticlesBg type="custom" config={config} bg={true} />
      <div className="s-hero__bg rellax" data-rellax-speed="-7"></div>

      <div className="row s-hero__content">
        <div className="column">

          <div className="s-hero__content-about">

            <h1><img src="./logo.png" alt="Botas IT" /></h1>

            <h3>
              A <span>technology company</span> that has made its professional capabilities a mission for it by <span>helping organizations</span> compete by providing the best creative solutions through <span>technical competitive advantages</span> to achieve their goals.
            </h3>
          </div>

        </div>
      </div>

      <div className="s-hero__scroll">
        <Link activeClass="active" to="contact" spy={true} smooth={true} offset={50} duration={500} href="#contact" className="s-hero__scroll-link smoothscroll">

          <span className="scroll-arrow">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style={{ fill: 'rgba(0, 0, 0, 1);transform:;-ms-filter:' }}><path d="M18.707 12.707L17.293 11.293 13 15.586 13 6 11 6 11 15.586 6.707 11.293 5.293 12.707 12 19.414z"></path></svg>
          </span>
          <span className="scroll-text">Scroll Down</span>
        </Link>
      </div>

    </section>
  </>
  );
}

export default Header;
