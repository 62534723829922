import React, { useState } from "react";
import { Circles } from "react-loader-spinner";

const Contact = () => {

  const [showForm, setShowForm] = useState(true);
  const [showLoading, setShowLoading] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const [Name, setName] = useState('');
  const [Email, setEmail] = useState('');
  const [Mobile, setMobile] = useState('');
  const [Message, setMessage] = useState('');
  return (
    <section id="contact" className="s-contact target-section">

      <div className="row s-contact__header">
        <div className="column large-12">
          <h3 className="section-header-allcaps">Contact Us</h3>
        </div>
      </div>

      <div className="row s-contact__content">

        <div className="column large-7 medium-12">

          <div className="eight columns">
            {showForm &&
              <form>
                <fieldset>
                  <div>
                    <label htmlFor="contactName">
                      Name <span className="required">*</span>
                    </label>
                    <input
                      value={Name}
                      onChange={evt => setName(evt.target.value)}
                      type="text"
                      size="35"
                      id="contactName"
                      name="contactName"
                    />
                  </div>

                  <div>
                    <label htmlFor="contactEmail">
                      Email <span className="required">*</span>
                    </label>
                    <input
                      value={Email}
                      onChange={evt => setEmail(evt.target.value)}
                      type="text"
                      size="35"
                      id="contactEmail"
                      name="contactEmail"
                    />
                  </div>

                  <div>
                    <label htmlFor="contactMobile">Mobile</label>
                    <input placeholder="+966"
                      value={Mobile}
                      onChange={evt => setMobile(evt.target.value)}
                      type="text"
                      size="35"
                      id="contactMobile"
                      name="contactMobile"
                    />
                  </div>

                  <div>
                    <label htmlFor="contactMessage">
                      Message <span className="required">*</span>
                    </label>
                    <textarea
                      value={Message}
                      onChange={evt => setMessage(evt.target.value)}
                      cols="50"
                      rows="15"
                      id="contactMessage"
                      name="contactMessage"
                    ></textarea>
                  </div>

                  <div>
                    <button onClick={(e) => {
                      e.preventDefault();
                      setShowForm(false);
                      console.log('sending');
                      setShowLoading(true);

                      setShowLoading(false);
                      //setShowSuccessMessage(true);
                      setShowErrorMessage(true);
                    }} className="mailtoui btn btn--primary h-full-width">Send</button>
                  </div>
                </fieldset>
              </form>}
            {showLoading &&
              <>
                <h4 style={{ textAlign: 'center', paddingBottom: 10, color: 'orange' }}>Sending your message, Please wait ...</h4>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Circles color="#336699" width="150" height="150" ariaLabel="loading" />
                </div>
              </>
            }
            {showErrorMessage &&
              <div style={{ color: 'red' }}>
                <i className="fa fa-xmark"></i> Sorry, we were unable to send your message, please try again later..
                <br />
              </div>
            }
            {showSuccessMessage &&
              <div style={{ color: 'green' }}>
                <i className="fa fa-check"></i> Your message was sent, thank you!
                <br />
              </div>
            }
          </div>

        </div>

        <div className="column large-4 medium-12" style={{ color: 'white' }}>

          <div className="row contact-infos">
            <div className="column large-12 medium-6 tab-12">
              <div className="contact-block">
                <p className="contact-block__content">
                  Saudi Arabia
                </p>
              </div>
            </div>
            <div className="column large-12 medium-6 tab-12">
              <div className="contact-block">
                <p className="contact-block__content">
                  Riyadh
                </p>
              </div>
            </div>
          </div>

        </div>
      </div>

    </section>
  );
}

export default Contact;
