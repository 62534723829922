/* ===================================================================
 * Ceevee 2.0.0 - Main JS
 *
 * ------------------------------------------------------------------- */
import Rellax from 'react-rellax';

/* Preloader
 * -------------------------------------------------- */
const ssPreloader = function () {

    const preloader = document.querySelector('#preloader');
    if (!preloader) return;

    window.addEventListener('load', function () {

        document.querySelector('body').classList.remove('ss-preload');
        document.querySelector('body').classList.add('ss-loaded');

        preloader.addEventListener('transitionend', function (e) {
            if (e.target.matches("#preloader")) {
                this.style.display = 'none';
            }
        });

    });

    // force page scroll position to top at page refresh
    // window.addEventListener('beforeunload' , function () {
    //     window.scrollTo(0, 0);
    // });

}; // end ssPreloader


/* Parallax
 * -------------------------------------------------- */
const ssParallax = function () {

    new Rellax('.rellax');

}; // end ssParallax


/* Move header menu
 * -------------------------------------------------- */
const ssMoveHeader = function () {

    const hdr = document.querySelector('.s-header');
    const hero = document.querySelector('#hero');
    let triggerHeight;

    if (!(hdr && hero)) return;

    setTimeout(function () {
        triggerHeight = hero.offsetHeight - 170;
    }, 300);

    window.addEventListener('scroll', function () {

        let loc = window.scrollY;


        if (loc > triggerHeight) {
            hdr.classList.add('sticky');
        } else {
            hdr.classList.remove('sticky');
        }

        if (loc > triggerHeight + 20) {
            hdr.classList.add('offset');
        } else {
            hdr.classList.remove('offset');
        }

        if (loc > triggerHeight + 150) {
            hdr.classList.add('scrolling');
        } else {
            hdr.classList.remove('scrolling');
        }

    });

}; // end ssMoveHeader


/* Mobile Menu
 * ---------------------------------------------------- */
const ssMobileMenu = function () {

    const toggleButton = document.querySelector('.s-header__menu-toggle');
    const headerNavWrap = document.querySelector('.s-header__nav-wrap');
    const siteBody = document.querySelector("body");

    if (!(toggleButton && headerNavWrap)) return;

    toggleButton.addEventListener('click', function (event) {
        event.preventDefault();
        toggleButton.classList.toggle('is-clicked');
        siteBody.classList.toggle('menu-is-open');
    });

    headerNavWrap.querySelectorAll('.s-header__nav a').forEach(function (link) {
        link.addEventListener("click", function (evt) {

            // at 800px and below
            if (window.matchMedia('(max-width: 800px)').matches) {
                toggleButton.classList.toggle('is-clicked');
                siteBody.classList.toggle('menu-is-open');
            }
        });
    });

    window.addEventListener('resize', function () {

        // above 800px
        if (window.matchMedia('(min-width: 801px)').matches) {
            if (siteBody.classList.contains('menu-is-open')) siteBody.classList.remove('menu-is-open');
            if (toggleButton.classList.contains("is-clicked")) toggleButton.classList.remove("is-clicked");
        }
    });

}; // end ssMobileMenu


/* Highlight active menu link on pagescroll
 * ------------------------------------------------------ */
const ssScrollSpy = function () {

    const sections = document.querySelectorAll(".target-section");

    // Add an event listener listening for scroll
    window.addEventListener("scroll", navHighlight);

    function navHighlight() {

        // Get current scroll position
        let scrollY = window.pageYOffset;

        // Loop through sections to get height(including padding and border), 
        // top and ID values for each
        sections.forEach(function (current) {
            const sectionHeight = current.offsetHeight;
            const sectionTop = current.offsetTop - 50;
            const sectionId = current.getAttribute("id");

            /* If our current scroll position enters the space where current section 
             * on screen is, add .current class to parent element(li) of the thecorresponding 
             * navigation link, else remove it. To know which link is active, we use 
             * sectionId variable we are getting while looping through sections as 
             * an selector
             */
            if (scrollY > sectionTop && scrollY <= sectionTop + sectionHeight) {
                document.querySelector(".s-header__nav a[href*=" + sectionId + "]").parentNode.classList.add("current");
            } else {
                document.querySelector(".s-header__nav a[href*=" + sectionId + "]").parentNode.classList.remove("current");
            }
        });
    }

}; // end ssScrollSpy

/* Alert boxes
 * ------------------------------------------------------ */
const ssAlertBoxes = function () {

    const boxes = document.querySelectorAll('.alert-box');

    boxes.forEach(function (box) {

        box.addEventListener('click', function (e) {
            if (e.target.matches(".alert-box__close")) {
                e.stopPropagation();
                e.target.parentElement.classList.add("hideit");

                setTimeout(function () {
                    box.style.display = "none";
                }, 500)
            }
        });

    })

}; // end ssAlertBoxes


/* back to top
 * ------------------------------------------------------ */
export const ssBackToTop = function () {

    const pxShow = 900;
    const goTopButton = document.querySelector(".ss-go-top");

    if (!goTopButton) return;

    // Show or hide the button
    if (window.scrollY >= pxShow) goTopButton.classList.add("link-is-visible");

    window.addEventListener('scroll', function () {
        if (window.scrollY >= pxShow) {
            if (!goTopButton.classList.contains('link-is-visible')) goTopButton.classList.add("link-is-visible")
        } else {
            goTopButton.classList.remove("link-is-visible")
        }
    });

}; // end ssBackToTop



/* initialize
 * ------------------------------------------------------ */
export const ssInit = function () {

    ssPreloader();
    ssParallax();
    ssMoveHeader();
    ssMobileMenu();
    ssScrollSpy();
    ssAlertBoxes();
    ssBackToTop();
};

