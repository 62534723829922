import React from "react";
import "./App.css";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import Contact from "./Components/Contact";

const App = () => {
  return (
    <>
      <Header />
      <Contact />
      <Footer />
    </>
  );
}


export default App;
